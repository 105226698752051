export const toUTCDate = (localTZDate: Date) => {
  if (!localTZDate) {
    return localTZDate;
  }
  const month =
    localTZDate.getMonth() + 1 < 10 ? `0${localTZDate.getMonth() + 1}` : localTZDate.getMonth() + 1;
  const day = localTZDate.getDate() < 10 ? `0${localTZDate.getDate()}` : localTZDate.getDate();
  return new Date(`${localTZDate.getFullYear()}-${month}-${day}T00:00:00.000Z`);
};

export const toISODate = (date: Date) => {
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${date.getFullYear()}-${month}-${day}`;
};

export const toLocalDate = (utcDate: Date) => {
  const newDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

  const offset = utcDate.getTimezoneOffset() / 60;
  const hours = utcDate.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export const WATERFORD_GLOBAL_SHARED = 'DEFAULT';

export const INITIAL_PROGRAMS_DATA_LOCAL_STORAGE_KEY = 'initialProgramsData';

export const PROVIDER_FORM_ID = 'PROVCARE';

export const AYLA_APP = 'ayla';
